import { isVisible } from '../utils/elementProperties';

export function hideOnClickOutside(element, callback) {
    function outsideClickListener(event) {
        if (!element.contains(event.target) && isVisible(element)) {
            // or use: event.target.closest(selector) === null
            callback(event);
            removeClickListener();
        }
    }

    function removeClickListener() {
        document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
}
