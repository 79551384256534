import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { initVhUnitOverwrite, debounce } from './utils/helpers';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
import { setupTabs } from './components/tabs';
import { setupYTVideos } from './components/video-yt';
import { setupSliders } from './components/slider';
import { watchFormFields } from './components/form';
import { setupIntro } from './components/intro';
import { setupUsp } from './components/usp';
import { setupFeaturedCases } from './components/featured-cases';
import { setupIntersect } from './utils/intersect';
import { setupProductVideo } from './components/product-video';

function init() {
    document.body.classList.remove('standby');

    setupIntersect();

    setupIntro();

    setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {
        initVhUnitOverwrite();
        setupLazyLoading();
        setupAccordions();
        setupFilters();
        setupOverlay();
        setupVideos();
        setupYTVideos();
        setupTabs();
        setupSliders();
        watchFormFields();
        setupUsp();
        setupFeaturedCases();
        setupProductVideo();
    });
}

init();
