import { getElementPosition, getElementTransform } from '../utils/elementProperties';
import { scrollTop } from '../utils/scroll';

export class CaseCarousel {
    /**
     *
     * @param {HTMLElement} mainElement
     */
    constructor(mainElement) {
        this.elements = {
            mainElement,
            nav: mainElement.querySelector('.featured-cases__controls-arrows'),
            container: mainElement.querySelector('.featured-cases__container'),
            anchors: mainElement.querySelectorAll('.featured-cases__container a'),
            cursor: mainElement.querySelector('.featured-cases__cursor')
        };

        this.currentCase = 1;
        this.caseWidth = this.getCaseWidth();
        this.nunmberOfCases = mainElement.querySelectorAll('.featured-cases__case').length;
        this.numberOfVisibleCases = this.calculateVisibleCases();
        this.remainingSpace = this.calculateRemainingSpace();

        this.inputDown = false;
        this.inputInitX = null;
        this.inputCurrentX = null;
        this.tolerance = 10;
        this.currentPosition = null;
        this.changeWaiting = 0;
        this.wasMovedByInput = false;
        this.savedUrl = null;

        this.elements.nav.addEventListener('click', this.handleNavClick, false);

        this.elements.container.addEventListener('mousedown', this.handleInputStart, false);
        this.elements.container.addEventListener('touchstart', this.handleInputStart, false);

        this.elements.container.addEventListener('mousemove', this.handleInputMove, false);
        this.elements.container.addEventListener('touchmove', this.handleInputMove, false);

        this.elements.container.addEventListener('mouseup', this.handleInputEnd, false);
        this.elements.container.addEventListener('touchend', this.handleInputEnd, false);

        Array.from(this.elements.anchors).forEach(a => {
            a.addEventListener('click', event => {
                event.preventDefault();

                if (!this.wasMovedByInput) {
                    window.location.href = a.getAttribute('href');
                }

                this.wasMovedByInput = false;
            });
        });

        this.elements.mainElement.addEventListener('mouseenter', () => {
            this.elements.mainElement.classList.add('active-cursor');
        }, false);

        this.elements.mainElement.addEventListener('mousemove', this.moveCursor, false);

        this.elements.mainElement.addEventListener('mouseleave', () => {
            this.elements.mainElement.classList.remove('active-cursor');
        }, false);

        this.elements.container.addEventListener('mouseenter', () => {
            this.elements.cursor.classList.add('active');
        }, false);

        this.elements.container.addEventListener('mouseleave', () => {
            this.elements.cursor.classList.remove('active');
        }, false);
    }

    getCaseWidth = () => {
        const firstCase = this.elements.mainElement.querySelector('.featured-cases__case');
        const style = window.getComputedStyle(firstCase);
        const width = parseFloat(style.width);

        return width;
    }

    calculateRemainingSpace = () => {
        const fullWidth = parseInt(window.getComputedStyle(this.elements.container).width);
        const remainingSpace = fullWidth - (this.numberOfVisibleCases * this.caseWidth);

        return remainingSpace;
    }

    calculateVisibleCases = () => {
        const fullWidth = parseInt(window.getComputedStyle(this.elements.container).width);
        const casesInARow = Math.floor(fullWidth / this.caseWidth);

        return casesInARow;
    }

    handleNavClick = event => {
        const prev = event.target.className.indexOf('prev');
        const next = event.target.className.indexOf('next');

        if (prev > -1) {
            if (this.currentCase > 1) {
                this.changeActiveCase(this.currentCase - 1);
            }

            return;
        }

        if (next > -1) {
            if (this.currentCase < this.nunmberOfCases && this.currentCase + this.numberOfVisibleCases <= this.nunmberOfCases) {
                this.changeActiveCase(this.currentCase + 1);
            }

            return;
        }
    }

    handleInputStart = event => {
        this.inputDown = true;

        const images = this.elements.container.querySelectorAll('img');
        const anchors = this.elements.container.querySelectorAll('a');

        Array.from(images).forEach(img => {
            img.setAttribute('draggable', false);
        });

        Array.from(anchors).forEach(a => {
            a.setAttribute('draggable', false);
        });

        const touch = getTouches(event);

        if (touch) {
            this.inputInitX = touch[0].clientX;
        } else {
            this.inputInitX = event.clientX;
        }

        this.currentPosition = getElementTransform(this.elements.container).translateX;
        // Create the event
        var event = new CustomEvent("lazy-load", { "lazy": "load" });

        // Dispatch/Trigger/Fire the event
        document.dispatchEvent(event);
    }

    handleInputMove = event => {
        if (this.inputDown) {
            const touch = getTouches(event);

            if (touch) {
                this.inputCurrentX = touch[0].clientX;

                if (this.inputCurrentX - this.inputInitX > 0) {
                    this.changeWaiting = this.currentCase - 1;

                    if (this.changeWaiting < 1) {
                        this.changeWaiting = 1;
                    }
                } else {
                    this.changeWaiting = this.currentCase + 1;

                    if (this.changeWaiting >= this.nunmberOfCases - this.numberOfVisibleCases + 1) {
                        this.changeWaiting = this.nunmberOfCases - this.numberOfVisibleCases + 1;
                    }
                }
            } else {
                this.inputCurrentX = event.clientX;

                if (this.inputCurrentX && this.inputInitX) {
                    this.moveContainer(this.inputCurrentX - this.inputInitX);
                }
            }
            // Create the event
            var event = new CustomEvent("lazy-load", { "lazy": "load" });

            // Dispatch/Trigger/Fire the event
            document.dispatchEvent(event);
        }
    }

    handleInputEnd = event => {
        this.inputDown = false;

        this.inputInitX = null;
        this.inputCurrentX = null;

        this.elements.container.classList.remove('no-transition');
        this.currentPosition = getElementTransform(this.elements.container).translateX;

        if (this.changeWaiting) {
            this.changeActiveCase(this.changeWaiting);

            this.changeWaiting = 0;
        }

        const images = this.elements.container.querySelectorAll('img');
        const anchors = this.elements.container.querySelectorAll('a');

        Array.from(images).forEach(img => {
            img.removeAttribute('draggable');
        });

        Array.from(anchors).forEach(a => {
            a.removeAttribute('draggable');
        });
        // Create the event
        var event = new CustomEvent("lazy-load", { "lazy": "load" });

        // Dispatch/Trigger/Fire the event
        document.dispatchEvent(event);
    }

    changeActiveCase = changeTo => {
        let transformDistance = (changeTo - 1) * this.caseWidth;

        if (transformDistance > 0) {
            transformDistance -= this.remainingSpace;
        }

        this.currentCase = changeTo;
        this.elements.container.style.transform = `translate3d(-${transformDistance}px, 0, 0)`;
    }

    moveContainer = inputMoved => {
        this.wasMovedByInput = true;
        this.elements.container.classList.add('no-transition');

        if (inputMoved > this.tolerance) {
            const newTranslate = this.currentPosition + inputMoved - this.tolerance;

            if (newTranslate < 0) {
                this.elements.container.style.transform = `translate3d(${newTranslate}px, 0, 0)`;
            } else {
                this.elements.container.style.transform = 'translate3d(0, 0, 0)';
            }
        } else if (inputMoved < -this.tolerance) {
            const newTranslate = this.currentPosition + inputMoved - this.tolerance;

            if (newTranslate > -(this.caseWidth * (this.nunmberOfCases - this.numberOfVisibleCases)) + this.remainingSpace) {
                this.elements.container.style.transform = `translate3d(${newTranslate}px, 0, 0)`;
            } else {
                this.elements.container.style.transform = `translate3d(${-(this.caseWidth * (this.nunmberOfCases - this.numberOfVisibleCases))}${this.remainingSpace}px, 0, 0)`;
            }
        }
    }

    moveCursor = event => {
        const position = {
            x: getElementPosition(this.elements.mainElement).left,
            y: getElementPosition(this.elements.mainElement).top
        };

        const positionRelativeToScreen = position.y - scrollTop;

        const cursorX = event.clientX - position.x;
        const cursorY = event.clientY - positionRelativeToScreen;

        this.elements.cursor.style.left = `${cursorX}px`;
        this.elements.cursor.style.top = `${cursorY}px`;
    }
}

export function setupFeaturedCases() {
    const modules = document.querySelectorAll('.featured-cases');

    if (modules) {
        Array.from(modules).forEach(module => {
            void new CaseCarousel(module);
        });
    }
}

function getTouches(event) {
    if (event.touches || event.originalEvent) {
        return event.touches || event.originalEvent.touches;
    } else {
        return false;
    }
}
