import Flickity from '../../../node_modules/flickity';
import { currentWindowWidth, onWindowResize } from '../utils/windowResize';
import { loadBgImage } from '../utils/lazyImage';

/**
 * The Slider class uses the cssAnimateNewHeight function to reveal hidden content.
 * Default element selector is data-action*="slider"
 *
 * @param {HTMLElement} container
 * @constructor
 */
export class Slider {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;
        this.nav = container.parentNode.querySelector('.slider__nav');

        if (this.container) {
            const mobileMode = !(currentWindowWidth >= 480);

            const lazyImgs = Array.from(this.container.querySelectorAll('.lazy'));

            this.flkty = new Flickity(this.container, {
                cellAlign: mobileMode ? 'center' : 'left',
                percentPosition: false,
                autoPlay: parseInt(this.container.getAttribute('data-autoplay')) || false,
                wrapAround: true,
                selectedAttraction: mobileMode ? 0.025 : 0.02,
                friction: 0.20,
                pageDots: !this.nav,
                draggable: true,
                lazyLoad: mobileMode ? 1 : 3,
                on: {
                    ready: () => {
                        // Do something when slider is rendered and ready
                    },
                    select: () => {
                        if (lazyImgs.length) {
                            lazyImgs
                                .filter(x => !x.classList.contains('lazy--loaded') || !x.classList.contains('lazy--loading'))
                                .forEach(elem => loadBgImage(elem));
                        }
                    }
                },
            });

            this.bindEvents();
        }
    }

    bindEvents() {
        const scope = this;
        //Change cell position on resize, when width is smaller than 768px
        onWindowResize(
            function alignSlider() {
                const mobileMode = !(currentWindowWidth >= 480);

                scope.flkty.option({ cellAlign: (mobileMode ? 'center' : 'left') });
                scope.flkty.reloadCells();
            }
        );

        // Following Code is only used in case you use Custom navigation

        if (this.nav) {
            const navButtons = Array.from(this.nav.children);

            // update buttons on select
            this.flkty.on('select', function () {
                const previousSelectedButton = scope.nav.querySelector('.is-selected');
                const selectedButton = scope.nav.children[scope.flkty.selectedIndex];
                previousSelectedButton.classList.remove('is-selected');
                selectedButton.classList.add('is-selected');
            });

            // cell select
            this.nav.addEventListener('click', function (event) {
                if (!event.target.matches('.dot')) {
                    return;
                }
                const index = navButtons.indexOf(event.target);
                scope.flkty.select(index, false, false);
            });
        }
    }
}

export function setupSliders(selector = '[data-action*="slider"]') {
    const sliders = document.body.querySelectorAll(selector);

    for (let i = 0; i < sliders.length; i++) {
        void new Slider(sliders[i]);
    }
}
