import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export function setupIntro() {

    const curtain = document.querySelector('.page-curtain');

    if (curtain) {
        enableScrollLock();

        window.addEventListener('load', () => {
            curtain.classList.add('pull-up');

            setTimeout(() =>  {
                curtain.classList.add('hide');
                disableScrollLock();
            }, 1500);
        });
    }
}
