import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { hideOnClickOutside } from '../utils/clickOutside';

export let nav,
    langPickers;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

export function setupNav(selector = '.nav', sticky = true) {

    nav = document.body.querySelector(selector);
    langPickers = document.body.querySelectorAll('.lang-picker');

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }
    }

    if (langPickers) {
        Array.from(langPickers).forEach(langPicker => {
            langPicker.addEventListener('click', function() {
                nav.classList.add('nav--language-selector-open');
                hideOnClickOutside(langPicker, function() {
                    nav.classList.remove('nav--language-selector-open');
                });
            });
        });
    }
}
