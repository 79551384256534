export class Usp {
    /**
     *
     * @param {HTMLElement} mainElement
     */
    constructor(mainElement) {
        this.elements = {
            mainElement,
            container: mainElement.querySelector('.usp__container'),
            nav: mainElement.querySelector('.usp__nav'),
            bullets: mainElement.querySelectorAll('.usp__nav span')
        };

        this.firstX = null;
        this.lastX = null;

        this.elements.mainElement.addEventListener('touchstart', this.handleStart);
        this.elements.mainElement.addEventListener('touchmove', this.handleMove, false);
        this.elements.mainElement.addEventListener('touchend', this.handleEnd, false);
        this.elements.nav.addEventListener('click', this.handleNavClick, false);
    }

    handleStart = event => {
        const firstTouch = getTouches(event)[0];
        this.firstX = firstTouch.clientX;
    }

    handleMove = event => {
        this.lastX = getTouches(event)[0].clientX;
    }

    handleEnd = () => {
        if (!this.firstX || !this.lastX) {
            return;
        }

        const diff = this.firstX - this.lastX;
        const currentActiveSlide = parseInt(this.elements.nav.querySelector('.active').getAttribute('data-nav'));

        if (diff > 50) {
            if (currentActiveSlide < 3) {
                this.changeSlide((currentActiveSlide + 1));
            }
        } else if (diff < -50) {
            if (currentActiveSlide > 1) {
                this.changeSlide((currentActiveSlide - 1));
            }
        }
    }

    handleNavClick = event => {
        const navClicked = parseInt(event.target.getAttribute('data-nav'));

        if (navClicked) {
            this.changeSlide(navClicked);
        }
    }

    changeSlide = changeTo => {
        const index = changeTo - 1;

        for (let i = 0; i < this.elements.bullets.length; i++) {
            this.elements.bullets[i].classList.remove('active');

            if (i == index) {
                this.elements.bullets[i].classList.add('active');
            }
        }

        this.elements.container.style.transform = `translate3d(-${window.innerWidth * index}px,0,0)`;
    }
}

export function setupUsp() {
    const modules = document.querySelectorAll('.usp');

    if (modules) {
        Array.from(modules).forEach(module => {
            const moduleNav = module.querySelector('.usp__nav');

            if (moduleNav) {
                const moduleNavStyle = window.getComputedStyle(moduleNav);

                if (moduleNavStyle.display !== 'none') {
                    void new Usp(module);
                }
            }
        });
    }
}

function getTouches(event) {
    return event.touches || event.originalEvent.touches;
}
