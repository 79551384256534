export function setupIntersect(target = '[data-intersect]') {
    const targets = document.querySelectorAll(target);

    const options = {
        threshold: 0.15
    };

    function observeSection(section) {
        const io = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('js-intersected');

                    observer.disconnect();
                }
            });
        }, options);

        io.observe(section);
    }

    Array.from(targets).forEach(observeSection);
}
