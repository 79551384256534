// Polyfills
if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        let el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */

/**
 * Function that opens new tab
 * and hides the activeTab if found
 *
 * @param {object|HTMLElement} e - click event or tab element
 */

// Used keycodes
const keycode = {
    arrowRight: 39,
    arrowLeft: 37,
    home: 36,
    end: 35
};

function openTab(e) {
    const tab = e.target ? e.target : e;

    if (!(event.ctrlKey || event.metaKey || event.shiftKey) && e.target) {
        e.preventDefault();
    }

    const tabContent = document.getElementById(tab.getAttribute('aria-controls').replace('#', ''));

    if (tabContent) {
        const activeTab = tab.parentNode.parentNode.querySelector('.tab--active');

        if (activeTab) {
            activeTab.classList.remove('tab--active');
            activeTab.setAttribute('aria-selected', false);
            activeTab.setAttribute('tabindex', -1);

            const activeTabContent = document.getElementById(activeTab.getAttribute('aria-controls').replace('#', ''));
            if (activeTabContent) {
                activeTabContent.classList.remove('tab__content--active');
                activeTabContent.setAttribute('aria-hidden', true);
            }
        }

        tab.classList.add('tab--active');
        tab.removeAttribute('tabindex');
        tab.setAttribute('aria-selected', true);
        tab.focus();
        tabContent.classList.add('tab__content--active');
        tabContent.setAttribute('aria-hidden', false);
    }
}

function openTabByHashId() {
    if (window.location.hash.indexOf('tab') > -1) {
        const tabContent = document.getElementById(window.location.hash.replace('#', ''));
        if (tabContent) {
            const tab = document.getElementById(tabContent.getAttribute('aria-labelledby'));
            openTab(tab);
        }
    }
}

/**
* Initialise Tabs with this function
* Will only run if given selector elements are found in DOM
*
* @param {string} selector - element selector string
*/
export function setupTabs(selector = '[data-action="tabs"]') {
    const tabsContainer = document.body.querySelectorAll(selector);

    if (tabsContainer) {
        for (let i = 0; i < tabsContainer.length; i++) {
            const tabs = tabsContainer[i].querySelectorAll('[role="tab"]');

            for (let t = 0; t < tabs.length; t++) {
                tabs[t].addEventListener('click', openTab);
            }
        }
    }

    openTabByHashId();

    window.addEventListener('hashchange', openTabByHashId, false);
}

window.addEventListener('keydown', event => {
    const focusedElement = document.activeElement;
    const tabs = document.activeElement.closest('.tabs__nav');

    if (focusedElement && tabs) {
        if (event.keyCode === keycode.arrowLeft) {
            // Open prevoius tab
            if (focusedElement.closest('li').previousElementSibling && focusedElement.closest('li').previousElementSibling.querySelector('.tab')) {
                openTab(focusedElement.closest('li').previousElementSibling.querySelector('.tab'));
            }
        }
        if (event.keyCode === keycode.arrowRight) {
            // Open next tab
            if (focusedElement.closest('li').nextElementSibling && focusedElement.closest('li').nextElementSibling.querySelector('.tab')) {
                openTab(focusedElement.closest('li').nextElementSibling.querySelector('.tab'));
            }
        }
        if (event.keyCode === keycode.home) {
            // Open first tab
            if (tabs.querySelector('li:first-child .tab')) {
                event.preventDefault();
                openTab(tabs.querySelector('li:first-child .tab'));
            }
        }
        if (event.keyCode === keycode.end) {
            // Open last tab
            if (tabs.querySelector('li:last-child .tab')) {
                event.preventDefault();
                openTab(tabs.querySelector('li:last-child .tab'));
            }
        }
    }
});
