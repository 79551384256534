import { disableScrollLock, enableScrollLock } from '../utils/scrollLock';
import { extractVideoIdFromUrl } from './video-yt';

let player;

export function setupProductVideo() {
    const videoToggle = document.querySelector('.toggle-product-video');

    if (videoToggle) {
        const url = videoToggle.getAttribute('href');
        const poster = videoToggle.getAttribute('data-poster');
        videoToggle.addEventListener('click', event => {
            event.preventDefault();

            if (extractVideoIdFromUrl(url)) {
                openVideoOverlay(url, poster);
            }
        });
    }
}

function openVideoOverlay(url, poster) {
    enableScrollLock();
    const closeButton = document.createElement('div');
    closeButton.classList.add('modal__close');
    closeButton.addEventListener('click', closeVideoOverlay);

    const playerContainer = document.createElement('div');
    playerContainer.setAttribute('id', 'modalPlayer');

    const playButton = document.createElement('div');
    playButton.classList.add('modal__play-button');
    playButton.addEventListener('click', startVideo);

    const playerPoster = new Image();
    playerPoster.src = poster;

    const modalBox = document.createElement('div');
    modalBox.classList.add('modal__box');
    modalBox.appendChild(playerContainer);
    modalBox.appendChild(playerPoster);
    modalBox.appendChild(playButton);

    const modalOverlay = document.createElement('div');
    modalOverlay.classList.add('modal__overlay');
    modalOverlay.addEventListener('click', closeVideoOverlay);

    const modal = document.createElement('div');
    modal.classList.add('modal');
    modal.appendChild(closeButton);
    modal.appendChild(modalOverlay);
    modal.appendChild(modalBox);

    document.body.appendChild(modal);

    player = new YT.Player('modalPlayer', {
        width: '100%',
        height: '100%',
        videoId: extractVideoIdFromUrl(url),
        playerVars: {
            rel: 0
        }
    });
}

function closeVideoOverlay() {
    disableScrollLock();

    document.querySelector('.modal').remove();
}

function startVideo() {
    player.playVideo();
    document.querySelector('.modal__play-button').remove();
    document.querySelector('.modal__box img').remove();
}
